.eight {
  --color: white;
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
  &:focus {
    text-decoration: none;
    color: white;
  }
  &:active {
    text-decoration: none;
    color: white;
  }
}

.custom-toast {
  min-height: 60px;
  background: #00e676;
  width: 95%;
  border-radius: 7px;
  margin: 0 auto;
  padding: 10px 20px;
  margin-top: 18px;
  position: fixed;
  z-index: 99999;
  color: white;
  left: 0;
  top: 7%;
  right: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  .close-btn {
    float: right;
    background: transparent;
    font-size: 20px;
  }
}
