.fcm-message-container {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;

  .fcm-avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: end;

    div {
      width: 28px;
      height: 28px;
      border-radius: 14px;
      border-width: 1px;
      border-style: solid;
      border-color: #bdbdbd;

      img {
        width: 18px;
        height: 18px;
        margin-left: 4px;
      }
    }
  }

  .fcm-message-body {
    text-align: left;
    margin-left: 8px;

    span {
      margin-left: 12px;
    }

    .message-wrapper {
      max-width: 320px;
      border-radius: 16px;
      border-width: 1px;
      border-style: solid;
      padding: 12px;
      border-color: #bdbdbd;

      .message {
        text-align: left;
        margin-bottom: 0px;
      }
    }
  }
}

.input-eta-btn-container {
  text-align: center;
  align-items: center;
}
