.iphone-home-padding {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.myshifts-label-active {
  color: #00152a;
}

.chat-badge {
  position: absolute;
  border-radius: 26px;
  background-color: var(--ion-color-danger);
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 3px;
  padding-right: 3px;

  line-height: 0;

  left: calc(62% + 0px);
  top: calc(22%);
  min-width: 15px;
  font-size: 11px;
  height: 16px;
  font-weight: normal;
}

// Attendance policy tab styles
$bottom-icon-width: 80px;
$bottom-icon-height: 22px;
$tab-max-width: 25%;
$tab-font-size: 11px;
$tab-font-size-selected: 11px;

.attendance-policy.open-shifts {
  max-width: $tab-max-width;
}
.attendance-policy.my-shifts {
  max-width: $tab-max-width;
}
.attendance-policy.ratings {
  max-width: $tab-max-width;
}
.attendance-policy.account {
  max-width: $tab-max-width;
}
.attendance-policy.tab-selected ion-label {
  color: #00152a;
  font-size: $tab-font-size-selected;
}

.attendance-policy .tab-button-icon {
  width: $bottom-icon-width;
  height: $bottom-icon-height;
}
.attendance-policy ion-label {
  font-size: $tab-font-size;
}

.chat-badge-attendance-policy {
  position: absolute;
  border-radius: 26px;
  background-color: var(--ion-color-danger);
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px 3px 0px 3px;

  line-height: 0;

  left: calc(50% + 2px);
  top: 2px;
  min-width: 15px;
  font-size: 11px;
  height: 16px;
  font-weight: normal;
}
.account-badge-attendance-policy {
  top: 4px;
}
