ion-button.payroll-arrow-icons {
  --padding-end: 0;
  --padding-start: 0;
}

ion-toast {
  --background: #e8f7ff;
  --color: #4e4e4e;
  --border-color: #aad8f8;
  --border-style: solid;
  --border-width: 1px;
}
