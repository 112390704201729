.home-pin-overlay {
  font-size: 16px;
  margin-left: -50%;
  margin-top: -73%;
  z-index: 500;

  .pin-box {
    z-index: 501;
    background: #003475;
    color: #fff;
    width: 200px;
    border-radius: 16px;
    padding: 1em;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
      0 1px 5px 0 rgb(0 0 0 / 20%);

    h5 {
      font-weight: 600;
      font-size: 1em;
      margin: 0px;
    }
    div {
      font-size: 0.8em;
    }
  }
  .pin-arrow {
    z-index: 500;
    height: 1.563em;
    width: 1.563em;
    background: #003475;
    transform: rotate(45deg);
    border-radius: 0 0 4px 0;
    margin-top: -1em;
    margin-left: 29.1%;
  }
}
