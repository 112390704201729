.license-content {
  width: 90%;

  h6 {
    font-weight: normal;
  }
}

.profile-image-icon {
  font-size: 20;
  position: "absolute";
  bottom: 0;
  right: 0;
}

.profile-image-view {
  margin-top: "auto";
}

.profile-image-editor-placeholder {
  background-color: var(--ion-color-light-tint);
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 256px;
    height: 256px;
    background-color: var(--ion-color-light-shade);
    border: 1px dashed var(--ion-color-dark-tint);
    margin: auto;
  }
}

.profileButton {
  z-index: 2;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  bottom: 0;
  background-color: var(--ion-color-dark-contrast);
  border: 1px solid var(--ion-color-dark-tint);
  border-radius: 50%;
}

.profilePic {
  width: 80px;
  position: relative;
  margin: 0 auto 8px auto;
}

.profile-step-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  & span {
    background-color: #c4c4c4;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    padding: 8px 14px;
    box-sizing: border-box;
  }
  & i {
    height: 1px;
    background-color: #000;
    position: relative;
    top: 17px;
    min-width: 50px;
  }
  & span.active {
    background-color: #0baee1;
  }
}

.profile-update-container {
  --offset-top: 60px;
  --offset-bottom: 200px;
}

.profile-update-helper-text {
  width: 80%;
  font-family: Roboto;
  font-size: 13px;
  line-height: 16px;
  display: inline-block;
  margin: 16px 0;
  letter-spacing: -0.078px;
  color: #707070;
}

.card-no-border {
  box-shadow: none;
}

.confirm-delete-check-container {
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding: 5px;
}

.confirm-delete-check {
  display: flex;
  margin-right: 7px;
  margin-left: 3px;
  justify-content: center;
  align-items: center;
}

.deletion-button.button-disabled {
  opacity: 0.9 !important;
}

.view-my-agreement-container {
  .view-my-agreement-text {
    margin: 0;
  }
}

.padding-medium {
  --padding-bottom: 1.15rem;
  --padding-top: 1.15rem;
}

.profile-image-upload-preview {
  display: flex;
  justify-content: center;
  .react-image-crop {
    width: calc(100% - 145px);
  }
}
