.license-manager {
  .licenses-summary-text {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
  }

  .add-license-text {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
  }

  .add-license-fab {
    width: 100%;
    bottom: 0px;
    background: white;
    border-top: 2px solid #e5e2e5;
    padding: 10px 14px;

    .add-license-button {
      box-sizing: border-box;
      --border-radius: 22px;
      --border-color: #5da0fb;
      --border-style: solid;
      --border-width: 2px;
    }
  }
}

.license-card {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  margin-left: 0;
  margin-right: 0;

  .license-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 20px;
    padding: 12px 0px;
    border-bottom: 1px solid #e0e0e0;

    .license-card-title {
      font-size: 17px;
      line-height: 20px;
      font-weight: 400;
      display: flex;
      .license-card-title-qualification {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 125pt;
        white-space: nowrap;
        margin-right: 4px;
      }
    }
  }

  .license-card-content {
    padding: 12px 36px;

    .license-card-content-detail {
      padding: 12px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 17px;
      line-height: 20px;
      color: #000000;
      font-weight: 400;
    }
  }
}

.license-details {
  --padding-bottom: 24px;
  --padding-top: 24px;
  --padding-start: 24px;
  --padding-end: 24px;

  .license-details-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    color: #333333;
    padding: 0px 20px;
  }

  .license-details-card {
    margin: 24px 0px;
    box-shadow: 0px 0px 20px rgba(156, 156, 156, 0.1);
    border-radius: 15px;

    .license-details-card-content {
      padding-top: 0px;
    }
  }

  .remove-license-button {
    box-sizing: border-box;
    --border-radius: 22px;
    color: #ca4031;
    --border-color: #ca4031;
    --border-style: solid;
    --border-width: 2px;
  }

  .add-license-fab {
    width: 100%;
    bottom: 0px;
    background: white;
    border-top: 2px solid #e5e2e5;
    padding: 10px 14px;

    .add-replacement-button {
      box-sizing: border-box;
      --border-radius: 22px;
      --border-color: #5da0fb;
      --border-style: solid;
      --border-width: 2px;
    }
  }
}

.add-license {
  --margin-top: 22px;
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --overflow: hidden;

  .add-license-wrapper {
    padding: 48px 24px 24px;
    height: calc(100vh - 56px - var(--offset-top, 0) - var(--offset-bottom, 0));
    overflow: auto;
  }

  .add-license-header {
    line-height: 17px;
    color: #333333;
    padding: 0px 20px;

    p {
      font-weight: 500;
      font-size: 14px;
    }

    h4 {
      font-size: 24px;
      font-weight: 800;
    }
  }

  .add-license-card {
    margin: 24px 0px;
    box-shadow: 0px 0px 20px rgba(156, 156, 156, 0.1);
    border-radius: 15px;

    .add-license-card-content {
      padding-top: 32px;

      .form-control {
        &:not(:last-child) {
          margin-bottom: 15px;
        }

        margin-top: 10px;

        .form-input {
          height: auto;
          --padding-start: 0px;
          --padding-end: 0px;
          --padding-top: 5px;
          --padding-bottom: 0px;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }

        .date-time-picker {
          padding: 2px 0px;

          &:not(.datetime-placeholder) {
            color: #000000;
          }
        }
      }

      .license-state-row {
        .license-multi-state-col {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        .form-control {
          margin-bottom: 0px;
          padding: 0px;

          .license-multi-state-toggle {
            padding-top: 5px;
            float: right;
          }

          .license-multi-state-label {
            float: right;
          }
        }
      }
    }
  }

  .submit-license-button {
    --border-radius: 90px;

    &[disabled=""] {
      --background: #bdbdbd;
    }
  }
}
.qualification-preference-shift-tag {
  --border-width: 1px;
  --padding: 2px;
  --margin-top: 0px;
  --margin-bottom: 0px;
  --border-color: #031426;
  --background: #fff;
  --border-radius: 6px;
  --color: #031426;
  .qualification-preference-shift-tag-text {
    max-width: 100pt;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
