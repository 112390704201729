.page-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ready-to-earn {
  position: relative;

  .code-input {
    width: 100%;
    background: #d9d9d9;
    padding: 12px 15px 12px 25px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 20px;
    position: relative;

    ion-icon {
      --ionicon-stroke-width: 40px;
      cursor: pointer;
      font-size: 20px;
    }
  }

  .static-content-container {
    .promotion-container {
      padding-left: 4%;
    }

    ol {
      padding-left: 8%;
    }

    ul>li {
      list-style-type: none;
      padding-right: 6px;
      display: flex;
      align-items: center;
    }

    .money-bag::before {
      content: "💰";
      font-size: 20px;
      padding-right: 6px;
    }

    .raise-hand::before {
      content: "🙌";
      font-size: 20px;
      padding-right: 6px;
    }
  }

  .cta {
    --border-radius: 20em;
    width: 100%;
  }

  .skip {
    margin-top: 8px;
    margin-bottom: 25px;
    width: 100%;

    &:not(.continue) {
      --border-radius: 100px;
    }

    &.greyed {
      --background: #828282;
    }
  }
}
