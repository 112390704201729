.height-30 {
  height: 30px !important;
}

.sent-home-btn-outline {
  .action-sheet-selected {
    outline-color: initial;
    outline-style: solid;
    outline-width: thin;
  }
}

.shift-cancel-btn-outline {
  .action-sheet-destructive {
    outline-color: initial;
    outline-style: solid;
    outline-width: thin;
  }
}

.attendance-policy-cancel-action-sheet {
  .action-sheet-wrapper {
    padding-bottom: 50px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .action-sheet-title {
    padding-top: 20px;
    font-weight: 700 !important;
  }
  .action-sheet-sub-title {
    padding: 10px 0px;
    font-size: 14px;
    font-weight: 500 !important;
  }
  .action-sheet-destructive {
    .action-sheet-button-inner {
      color: red;
    }
  }

  .action-sheet-button {
    color: #333;
    font-weight: 500 !important;
    font-size: 14px;
    padding: 30px 0;
  }

  .action-sheet-group-cancel {
    border-radius: 25px;
  }

  .action-sheet-cancel {
    height: 45px;
    padding: 18px 0;
  }
}

.attendance-policy-cancel-modal {
  --max-height: 75%;
  .modal-wrapper {
    align-self: flex-end;
    ion-list-header {
      ion-label {
        margin-bottom: 0px;
      }
      ion-button {
        margin-bottom: 5px;
      }
    }
    ion-content {
      ion-radio {
        margin: 2px 9px 2px 20px;
      }
      --padding-bottom: 20px;
    }
    .cancellation-policy-message {
      padding: 0px 60px 10px 60px;
      text-align: center;
      color: black;
      font-size: 17px;
    }
    .how-attendance-score-work {
      text-decoration: underline;
      margin-bottom: 50px;
    }
    .description-container {
      margin: 0 20px;
    }
    .facility-cancelled-message {
      padding: 0px 35px 50px 35px;
      text-align: center;
      color: black;
      font-size: 16px;
    }

    .facility-cancelled-share-warning {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 15px;
    }
    .facility-cancelled-share-warning-container {
      --padding-start: 0;
      max-height: 50px;
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: 5px;
        height: 25px;
        width: 25px;
      }
    }
  }
}

.sent-home-request-modal {
  .modal-wrapper {
    align-self: flex-end;
    ion-list-header {
      ion-label {
        margin-bottom: 0px;
      }
      ion-button {
        margin-bottom: 5px;
      }
    }
    ion-content {
      ion-radio {
        margin: 2px 9px 2px 20px;
      }
      --padding-bottom: 20px;
    }

    .description-container {
      margin: 0 20px;
    }
    .facility-cancelled-share-warning {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 15px;
    }
    .facility-cancelled-share-warning-container {
      --padding-start: 0;
      max-height: 50px;
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: 5px;
        height: 25px;
        width: 25px;
      }
    }
  }
}

.red-color {
  color: red;
}

.cancel-button-attendance-policy-cancel-modal {
  color: #666666;
  font-size: 20px;
  margin-right: 16px;
  margin-bottom: 5px;
}

.font-600 {
  font-weight: 600;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.ion-text-area-80 {
  ion-textarea {
    textarea {
      min-height: 80px;
    }
  }
}

.sent-home-toolbar {
  position: relative;
}

.sent-home-request__back-btn {
  position: relative;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: 0;
  left: -12px;
}

.sent-home-request__header-title {
  font-size: 17px;
  line-height: 19.92px;
  text-align: center;
  font-family: Roboto;
}
.alert-button.sc-ion-alert-ios {
  color: #000;
  font-weight: 600;
}

.my-shift-details-alert .alert-message {
  font-size: 16px;
}

ion-footer.attendance-policy-shift-action-footer {
  padding: 0 16px 24px 16px;
  background-color: rgba(187, 216, 255, 0.5);
  border-radius: 20px 20px 0 0;
  padding-top: 16px;

  ion-button {
    margin: 16px 0 0;

    &.attendance-policy-cancel-button.button-clear {
      margin: 0;
    }

    &.button-outline {
      --background: white; // Since the background is grey, buttons with a transparent background are changed to a white background
    }
  }
}

.nfc-tags-padding {
  padding-inline-start: 20px;
}
