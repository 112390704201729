.modal-component {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  .modal-wrapper {
    width: 90%;
    max-width: 35em;
    border-radius: 15px;
    position: absolute;
    display: block;

    max-height: unset !important;
    height: unset !important;
    position: relative !important;
    contain: unset !important;

    .ion-page {
      position: relative !important;
      contain: unset !important;
    }
  }

  .modal-component-content {
    --ion-font-family: "SF Pro Display";
    font-family: "SF Pro Display";

    --modal-component-text-color: black;
    --modal-component-highlight-color: #10b981;
    --color: black;

    max-height: 95vh !important;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 1rem;
    height: 100%;

    h1 {
      font-size: 22px;
      font-weight: 800;
      display: inline;
    }
    h2 {
      font-size: 22px;
      display: inline;
    }
    strong {
      color: black;
      display: inline;
    }

    .cbh-bunny-container {
      margin: 1rem;
      width: 15%;
    }

    .not-now-button {
      margin: 0;
    }
    .modal-button {
      text-transform: uppercase;
      min-width: 50%;
      --box-shadow: none;
      --border-radius: 50px;
      --background: #1c69d1;
      transition: all 0.3s ease-in;
      font-size: 16px;
      &.slim {
        height: 34px;
      }
    }

    .modal-component-footer,
    .modal-component-header {
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
    }

    .modal-component-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: scroll;

      margin: 0;

      ul,
      ol {
        padding-bottom: 0;
        margin: 1rem 0 0 0;
        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    .modal-component-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;

      .not-now-button {
        text-transform: uppercase;
        color: #4f4f4f;
        font-size: 16px;
      }
    }
  }
}
