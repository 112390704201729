.custom-padding-notif-card {
  padding: 15px 6px;
}

.ion-top-label {
  font-weight: "600";
  font-size: "18px";
  margin-bottom: "-15px";
}

.dot-spinner {
  text-align: center;
}

.text-muted {
  color: #a9a9a9
}