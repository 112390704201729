.card-header-label {
  font-size: 15px;
  color: #333333;
  font-weight: bold;
}

.facility-name-title {
  color: #333333 !important;
}

.site-collapse-header {
  margin: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 25px;
  background: white;
}

.site-collapse-header-panel {
  background: white;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  border-radius: 25px !important;
  min-height: 70px;
  border: 0px !important;
  padding-top: 0px;
  margin-top: 50px !important;
}

.important-info-disabled {
  pointer-events: none;
}

.site-collapse-panel-inner-text {
  margin: 10px;
}

.card-section-body {
  font-size: 14px;
  color: #4f4f4f;
}

.card-header-collapse {
  border-radius: 20px;
}

.facility-name {
  text-transform: uppercase;
}

.testing-time-period {
  font-weight: bold;
  color: blue;
}

.inner-text {
  margin: 30px;
  background: #f3f3f3;
  padding: 20px;
  margin-top: -30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.covid-item-background {
  --background: #eeeeee;
}

.covid-list-background {
  background: #eeeeee;
  padding: 5px;
}

.facility-profile {
  margin: 20px;
}

.covid-list-header {
  color: black !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.covid-input-number {
  font-weight: bold;
  font-size: 20px;
  color: blue;
}

.covid-input-title {
  padding-left: 10px;
  font-size: 14px;
  color: #4f4f4f;
}

.covid-input-text {
  font-weight: normal;
  padding-left: 20px;
  font-size: 14px;
  color: #4f4f4f;
}

.cancelation-payout-list {
  font-weight: 400;
}

.extra-pay-period {
  padding: 10px 0;
  color: #4f4f4f;
  font-size: 0.65rem;
  font-weight: normal;
  --background: #f3f3f3;
  display: block;

  .item-inner {
    min-height: 0;
  }
}

// Using id here to avoid using important! and due to a conflicting class.
// This should be redone so the overriding class does not cover this one
#extra-time-pay-link {
  display: flex;
  align-items: center;
  color: #1c69d1;
  cursor: pointer;
  padding: 5px 0;
  font-size: 0.95em;
}

.profile-input {
  font-size: 14px;
  color: #4f4f4f;
}

.open-maps-container {
  border-radius: 10px;
  background: #dedede;
  vertical-align: -webkit-baseline-middle;
  color: var(--ion-color-tertiary);
  padding: 30px;
  font-size: 20px;
  margin-bottom: 0;

  &.btn {
    padding: 10px !important;
  }
}

.open-maps-container > hr {
  border-top: 1px solid #545458;
  opacity: 0.5;
}

.open-maps-cancel-btn {
  margin-bottom: 5px auto 0 auto;
  border-radius: 10px;
  background: #fff;
  vertical-align: -webkit-baseline-middle;
  color: var(--ion-color-tertiary);
  padding: 12px;
  font-size: 20px;
  font-weight: 500;
}

.facility-photo {
  width: 100%;
}

.imgCarousel {
  height: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.facility-photo-container {
  position: relative;
  width: 100%;

  .facility-street-map-view {
    width: 100% !important;
  }
}

.verification-details > p {
  text-align: center;
  color: #333333;
  font-size: 10px;
  padding: 20px;
  font-weight: 400;
}

// FacilityOpenShifts.scss
.facility-button {
  width: 100%;
  height: 40px;
  font-family: "SF Pro Display";
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  font-weight: 600;
  --ion-color-primary: #1c69d1;
  --ion-color-secondary: #828282;
  min-height: 40px;
  --border-width: 0.5;
}

.facility-button.btn-outline {
  --ion-color-primary: #032e68;
  --ion-color-secondary: #828282;
}

#shifts-list {
  min-height: 40vh;
}

.facility-openshifts {
  .errorColor {
    color: #da0000 !important;
  }

  margin: 4em 0em;
  // FIXME: Remove the usage of css and prefer using sx props
  // margins are independent of font-size, so should not be based on em or rem
  // font should be rem based in case the usage cannot be achieved using Text and Title defined in @clipboard-health/ui-react
  .facility-openshifts-title {
    display: flex;
    align-items: center;
    color: #032e68;
    font-size: 1.3em;
    line-height: 1.4em;
    font-weight: bold;

    .hcf-icon {
      margin-right: 0.2em;
      fill: #032e68;
      stroke: #032e68;
    }
  }

  .no-shifts-subtitle {
    color: #828282;
    margin: 0em 1.1em;
  }

  .facility-openshifts-title.no-shifts {
    color: #828282;
  }

  .facility-openshifts-item {
    font-family: "SF Pro Display";
    margin: 0.8em 0;

    .openshifts-date {
      margin-left: 1.4em;
      margin-right: 1.4em;
      color: #828282;
      font-size: 1.1em;
      line-height: 1.2em;
      font-weight: 600;
    }

    .shift-section {
      padding: 1em 2.5em 1.5em 2.5em;
      border-bottom: 1px solid #e0e0e0;

      .shift-time {
        font-size: 1.2em;
        line-height: 1.3em;
        font-weight: 400;
      }

      .shift-pay {
        margin-top: 0.3em;
        margin-bottom: 1em;
        color: #1c69d1;
        font-size: 1.1em;
        line-height: 1.2em;
        font-weight: 500;
      }
    }
  }
}

.facilityReviews-modal {
  --backdrop-opacity: 0.2;
  --border-radius: 10px 10px;
  --max-height: 50%;
  --max-width: 75%;

  ion-item {
    --padding-end: 0px;
    --inner-padding-bottom: 0;
    --inner-padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-top: 0;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .input-wrapper {
    flex-grow: unset;
  }

  .item-inner {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .item-native {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .list-ios.list-inset {
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }
}

.v-center {
  display: flex;
  align-items: center;
}

.lifetime_earning_stopwatch {
  font-size: 1.3em;
}

.check-in-instruction-category-name {
  font-weight: bold;
}

.check-in-instruction-category-description {
  font-weight: lighter;
}
