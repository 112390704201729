.tooltip {
  font-size: 10px;
  width: 320px;
  word-wrap: break-word;
  background-color: #5da0fb;
  color: #FFF;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;
  border: 1px solid;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -55%;
  left: 50%;
  margin-left: -311px; /* Use half of the width, to center the tooltip */
}


 /*for the bottom arrow*/
.tooltip::after {
  content: " ";
  position: absolute;
  top: -80%; /* At the top of the tooltip */
  left: 96%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: #5da0fb transparent transparent transparent;
  transform: rotate(-180deg);
}
