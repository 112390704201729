@media (max-height: 575px) {
  .cbh-logo {
    height: 40vh;
  }
}

.cbh-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.referral-container {
  font-family: "SF Pro";
  .card {
    font-family: "SF Pro";
    padding: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 20px rgba(156, 156, 156, 0.1);
    border-radius: 10px;
    overflow: hidden;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .disabled-button-variant {
    &[disabled=""] {
      --background: #bdbdbd;
    }
  }
  .spaced-button {
    --padding-top: 1.5rem;
    --padding-bottom: 1.5rem;
    --padding-start: 2rem;
    --padding-end: 2rem;
  }
}
