.onboarding-page {
  padding-top: env(safe-area-inset-top);
  font-family: Roboto;

  .cbh-logoh-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    img {
      width: auto;
      margin: 0 auto;
    }
  }

  form {
    width: 100%;
  }

  @keyframes confetti {
    0% {
      opacity: 0;
      transform: translateY(-20%) rotate(0deg);
    }
    10% {
      opacity: 1;
    }
    45% {
      opacity: 0.75;
      transform: translateY(-800%) rotate(270deg);
    }
    80% {
      opacity: 0.25;
    }
    100% {
      opacity: 0;
      transform: translateY(2000%) rotate(720deg);
    }
  }

  .confetti {
    opacity: 0;
    position: absolute;
    width: 1.5rem;
    height: 0.5rem;
    transition: 200ms ease;
    animation: confetti 1s ease-in-out 1;
    z-index: 1000;
  }

  #confetti-wrapper {
    overflow: hidden !important;
  }

  .shadowedCard {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgb(156 156 156 / 10%);
    border-radius: 15px;
    padding: 15px 0;
    margin: 0 0 20px 0;
  }

  .content-layout {
    .cbh-logoh-wrapper {
      margin-top: -30px;
      margin-bottom: 10px;
    }

    ion-icon {
      transition: all 0.3s ease-in;
      &.active {
        transform: rotate(90deg);
      }
    }

    .openShifts-wrapper {
      border-radius: 15px;
    }

    .potentialShift-date {
      font-size: 13px;
      font-weight: 600;
    }

    .shifts-list {
      &:not(:last-child) {
        border-bottom: 1px solid #cccccc;
      }
    }

    .pShift-card {
      margin-bottom: 0;
      padding: 0;
      &:not(:last-child) {
        border-bottom: 1px solid #cccccc;
      }
      &.active {
        box-shadow: 2px 2px 20px rgba(156, 156, 156, 0.15);
      }
      .shiftName-details-wrap {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in;
        ion-item {
          --background: #f3f3f3;
          .shift-details {
            .shift_pay {
              color: #1c69d1;
              font-weight: 600;
              font-size: 12px;
            }
            .shift_date,
            .facility_address,
            .facility_name {
              color: #4f4f4f;
            }
            .facility_distance {
              margin-top: 6px;
            }
          }
        }
      }
      .shiftcard-text {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .qualification-card,
    .document-card {
      &.active,
      &.selected {
        box-shadow: 2px 2px 20px rgba(156, 156, 156, 0.15);
        border: 1px solid #cccccc;
      }

      &.selected {
        top: 0px;
        width: 100%;
        z-index: 100;
      }

      .qualification-item,
      .document-item {
        padding: 0;
        margin: 0;
      }

      .license-form-wrap {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in;
      }

      .license-form {
        padding: 16px;
        margin-top: 8px;

        .form-control {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
          .form-label {
            margin-bottom: 2px;
          }
          .form-input {
            height: auto;
          }
        }
      }

      ion-label.aqf-label {
        margin: 0;

        p {
          text-overflow: unset;
          overflow: unset;
          white-space: pre-wrap;
          &.licenseType {
            color: #828282;
            font-size: 12px;
            margin-bottom: 2px;
          }
          &.licenseName {
            color: #333333;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      &.other-item {
        ion-label {
          color: #828282;
          margin-bottom: 6px;
        }
      }
    }

    .qualification-card {
      &.selected {
        position: sticky;
      }
    }
    .accordion.active {
      .license-form-wrap,
      .shiftName-details-wrap {
        max-height: 450px;
      }
    }

    .cbh-welcome-text {
      padding-left: 20px;
      li {
        list-style: none;
        margin: 16px 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        h4 {
          color: #333333;
          font-size: 15px;
          font-weight: 600;
        }
        p {
          color: #333333;
          font-size: 13px;
          font-weight: 400;
        }
      }

      &.infosteps {
        padding-left: 0px;
        li {
          position: relative;
          padding-left: 40px;
          margin-bottom: 24px;
          h4 {
            margin-bottom: 3px;
          }
          span {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 5px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            ion-icon {
              width: 22px;
              height: 22px;
            }
            &.step {
              border: 2px solid #e0e0e0;
              border-radius: 50%;
              color: #e0e0e0;
            }
            &.doing {
              border: 2px solid #1c69d1;
              color: #1c69d1;
            }
            &.done {
              border: 2px solid #1c69d1;
              background: #1c69d1;
              color: #ffffff;
            }
          }
        }
      }
    }

    .agentQType {
      margin-bottom: 30px;
      p {
        margin-bottom: 2px;
        &.licenseType {
          color: #828282;
          font-size: 12px;
          margin-bottom: 2px;
        }
        &.licenseName {
          color: #333333;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    &.adjust-form-height {
      // Helps in lifting the button below the viewing height;
      max-height: calc(100% - 70px);
    }
  }
}
