.coworker-referal-container {
  font-family: "SF Pro";

  .card {
    font-family: "SF Pro";
    padding: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 20px rgba(156, 156, 156, 0.1);
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #000000;
    }

    .divider {
      height: 1.2px;
      border-radius: 4px;
      background-color: #e0e0e0;
      align-self: center;
      width: 95%;
      margin: 3px;
    }

    .validationContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: -14px;
      span {
        font-size: 12px;
        color: #dc3028;
      }
      ion-icon {
        padding-top: 2px;
        margin-right: 4px;
        color: #dc3028;
      }
    }
    .validationContainer.isValid {
      span {
        color: #18dc68 !important;
      }
      ion-icon {
        color: #18dc68 !important;
      }
    }
  }

  .referralListContainer {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    .referralCard {
      background-color: #ffffff;
      font-family: "SF Pro";
      padding: 15px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 20px rgba(156, 156, 156, 0.1);
      border-radius: 10px;
      overflow: hidden;
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      ion-icon {
        color: #5da0fb;
        width: 25px;
        height: 25px;
        margin: 0 5%;
      }
    }
    .referralCard.wasPaid {
      span {
        color: #828282 !important;
      }
      background-color: #e0e0e0;
    }

    .referrerInfo {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-left: 5%;

      .referrerName {
        color: #828282 !important;
        font-size: 12px;
        text-align: left;
      }

      .referrerCode {
        color: #000000 !important;
        font-size: 18px;
        text-align: left;
      }
    }

    .bonusInfo {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;

      .bonusAmount {
        color: #18dc68 !important;
        font-size: 18px;
        text-align: center;
        margin-bottom: -4px;
      }

      .bonusStatus {
        color: #bdbdbd !important;
        font-size: 12px;
        max-width: 130px;
        text-align: center;
        line-height: 12px;
      }
    }
  }
}
