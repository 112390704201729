ion-button.payroll-arrow-icons {
  --padding-end: 0;
  --padding-start: 0;
}
.container {
  font-size: medium;
}

.card-style {
  font-size: small;
  padding: 5px;
}

.small-title {
  font-weight: 300;
  color: #4f4f4f;
}

.summary-nav {
  position: absolute;
  left: 0;
  right: 0;
}

.hack {
  position: relative;
}
.details-nav {
  background-color: rgb(0, 82, 204);
  color: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding: 4px;
}

.hack::after {
  content: " ";
  width: 100px;
  height: 30px;
  white-space: pre;
}

.details-container {
  color: #333;

  & > div:nth-of-type(odd) {
    background-color: #f3f3f3;
  }

  .details {
    padding: 7px;
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .badge-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .paid-badge {
        font-size: 12px;
        font-weight: bold;
        margin: 5px 0 5px 5px;
        line-height: 1;
        padding: 3px 8px;
        border-radius: 10px;
        color: white;
      }
    }
    .amount {
      margin-right: 4px;
    }
  }

  .empty-shifts {
    padding: 26px 40px;
    text-align: center;
  }

  .asterisk {
    padding: 6px 40px;
    text-align: center;
    color: gray;
  }
}

.icon-background {
  padding: 4px;
  background-color: #5da0fb;
  border-radius: 50%;
  width: 24px;
  height: 24px;

  fill: white;
  color: white;

  ion-icon {
    display: contents;
  }

  --ionicon-stroke-width: 70px;
}

.ionicon-stroke-width {
  stroke-width: 70px !important;
}

.full-paid {
  background-color: #219653;
}

.full-paid-amount {
  font-weight: bold;
  color: #219653;
}

.paid-amount {
  font-weight: bold;
  color: #219653;
}

.pending-amount {
  font-weight: bold;
  color: goldenrod;
}

.partially-paid {
  background-color: goldenrod;
}

.partially-paid-amount {
  color: #219653;
  font-weight: bold;
  text-align: right;
  line-height: normal;
}

.bonus-reason {
  color: #777777;
  font-size: 0.7rem;
}

.partially-paid-left {
  color: rgb(28, 105, 209);
}

.bubbleContainer {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 20px rgba(156, 156, 156, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
.payrollContainer {
  padding: 18px 24px;
  max-width: 540px;
  width: 100%;
  margin: auto;
  font-family: "SF Pro";
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 399px) {
    padding: 12px;
  }

  .helperText {
    margin-top: -4px;
    padding: -8px 0px 0px 0px;
    font-family: "SF Pro";
    font-size: 12px;
    color: #828282;
    text-align: center;
    font-style: italic;
  }

  .contact-stripe-button {
    margin: 0 auto;
  }
}

.payroll-settings-automatic-option {
  padding: 0;
  margin: 0;

  ion-button {
    font-size: 14px;
    color: #1c69d1;
    --background: #f5f5f5;
  }

  &.selected-option {
    ion-button {
      color: #fff;
      --background: #1c69d1;
    }
  }
}