.channels-container {
  .channels-container {
    border: 1px solid #d1d1d1;
    border-right-width: 0;
    min-width: 300px;
    max-width: 30%;
    flex-grow: 1;
    overflow: scroll;
  }

  .channels-header {
    border-bottom: 1px solid #d1d1d1;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 18px;
    text-align: left;
  }

  b {
    font-weight: 600;
  }

  .channel {
    display: grid;
    grid-template-columns: auto auto;
    padding: 15px 10px;
    border-left: 4px solid transparent;
    cursor: pointer;
    column-gap: 30px;
    justify-items: start;

    &.channel-last {
      box-shadow: 0px 2px 3px #00000029;
    }

    &.channel-not-first {
      border-top: 1px solid #d1d1d1;
    }

    &:hover {
      background-color: #eeeeee;
    }

    &-unread {
      background-color: #f15659;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      color: white !important;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-name {
      margin-bottom: 4px;
      font-size: 16px;
    }

    &-name,
    &-message {
      max-width: 100%;
    }

    &-last-message,
    &-unread {
      justify-self: end;
      max-width: 100%;
    }

    &-message,
    &.unread {
      align-self: flex-end;
    }

    &-message,
    &-last-message,
    &-unread,
    &-upcoming-shift {
      color: gray;
    }

    &.selected {
      background-color: #d1e3ff;
      border-left-color: hsl(217deg 100% 66%);

      .channel-name {
        color: #1f75ff;
      }
    }
  }

  .channel,
  .channel div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
