.upload-prompt {
  --height: 230px;
  --width: 300px;
  --backdrop-opacity: 0.5;
  margin: 0 auto !important;
}

.upload-prompt .popover-content {
  position: absolute !important;
  bottom: 1rem !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 14px;
  margin: 0 auto !important;
  overflow: visible;
  background: none;
  width: 90%;
}
