:root {
  .shift-timeline {
    padding-top: 30px;
    padding-left: 10px;

    ion-item {
      --padding-start: 5px;

      ion-label {
        margin: 0;
      }
    }
  }

  .rm-border-last-child {
    ion-item {
      &:last-child {
        --border-color: rgb(0, 0, 0, 0);
      }
    }
  }

  .time-elapsed {
    text-align: center;
    .time {
      font-size: 16px;
      font-weight: 600;
    }
    .second {
      font-size: 10px;
    }
  }

  .list-overlay {
    position: relative;
    .overlay {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 82%;
      z-index: 2;
      background: rgb(255, 255, 255); /* Old browsers */
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      padding: calc(var(--ion-safe-area-left, 0px) + 20px);
      .overlay-content {
        position: absolute;
        bottom: 0;
        text-align: left;
        .text-button {
          display: block;
          color: var(--ion-color-primary-shade);
          text-decoration: underline;
          font-size: 12px;
          margin-top: 15px;
        }
      }
    }
  }
}
.btn-sub-text {
  font-size: 12px;
  padding: 5px 0;
}
.shift-timecard-title-btn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 0.8;
  margin-top: 6px;
}

// image preview styling
.image-preview-alert {
  --min-width: 90%;
  .alert-message {
    span {
      position: absolute;
      top: 10px;
      background: black;
      right: 10px;
      color: #ffffff;
      width: 20px;
      height: 20px;
      border-radius: 40px;
    }
  }
  .alert-wrapper.sc-ion-alert-ios {
    border: 3px solid #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .alert-message.sc-ion-alert-ios {
    padding: 0 !important;
  }
  .alert-head.sc-ion-alert-ios {
    padding: 0 !important;
  }
}

.image-preview-modal {
  position: fixed;
  background: #00000070;
  height: 100vh;
  width: 100vw;
  box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.16);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .img-wrap {
    border: 4px solid white;
    border-radius: 12px;
    overflow: hidden;
    max-height: 90vh;
    overflow-y: scroll;
  }
  img {
    border-radius: 10px;
    width: 100%;
  }
}
.cancel-icon {
  position: absolute;
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 50px;
  text-align: center;
  line-height: 1;
  top: -6px;
  right: -6px;
}
.imgCancel {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #e31937;
  line-height: 0.8;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  text-align: center;
  color: white;
  z-index: 99;
}
.imgView {
  position: fixed;
  width: 70px;
  height: 70px;
  border: 3px solid var(--ion-color-shift-custom-contrast);
  border-radius: 8px;
  margin: 10px 20px;
  right: 10%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .img {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    display: block;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      display: block;
    }
  }
}
.info-text {
  color: var(--ion-color-primary);
  text-decoration-line: underline;
  padding: 5px 0 15px 20px;
  font-weight: 600;
  font-size: 12px !important;
}
.info-text-extend {
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
}
.how-to-clockin-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
}
.how-to-clock-in-modal {
  --min-width: 300px;
  .alert-message {
    text-align: left;
    font-size: 12px;
    span {
      padding-bottom: 5px;
      display: inline-block;
      font-weight: 600;
      color: var(--ion-color-light-contrast);
    }
    p {
      color: var(--ion-color-text) !important;
    }
  }
}

.no-time-card {
  color: black;
  text-decoration: underline;
  margin: 0 auto;
  font-weight: bold;
}
