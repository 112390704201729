.alert-popup-container {
  .alert-button-group {
    flex-direction: column;

    .negative-action,
    .positive-action,
    .neutral-action {
      border-top: none;
      border-bottom: none;
      border-radius: 2rem;
      font-weight: 600;
      margin: 0.5rem 1rem;
    }
    .positive-action {
      border: 2px solid #5da0fb;
      color: #5da0fb;
    }
    .negative-action {
      border: 2px solid #ca4031;
      color: #ca4031;
    }
    .neutral-action {
      border: 2px solid #828282;
      color: #828282;
      margin-top: 0rem;
    }
  }
  .alert-sub-title {
    color: #000000;
  }
}
