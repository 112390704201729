.edit-icon {
  color: rgba(169, 169, 169, 255);
}
.timesheet-submission {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.shift-time-summary {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.time-selector-button {
  color: black;
  padding: 0;
  margin: 0;
  height: auto;
  opacity: 1;
  --padding-start: 0px;
  --padding-end: 0px;
  --box-shadow: none;
  --ripple-color: transparent;

  .time-selector {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #333333;
    padding: 0px;
    width: 100%;
    height: 100%;
    line-height: 1.2rem;
  }

  button.clear-time-button {
    background: none;
    padding: 0;
    ion-icon {
      color: rgba(0, 0, 0, 0.3);
      width: 20px;
      height: 20px;
    }
  }
}

.total-work-time-label {
  color: rgba(79, 79, 79, 255);
  font-weight: 600;
}
.total-work-time {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: black;
  display: flex;
  justify-content: end;
}
:root {
  .cancel-button {
    --border-radius: 25px;
    --background: lightgray;
    --box-shadow: none;
    --color: black;
    text-decoration: none;
    height: 30px;
    background-color: transparent;
    display: flex;
  }
  .shift-time-summary-button {
    width: 100%;
    margin: 4px 0;
  }
  .edit-time-input-disable {
    color: black;
    font-weight: 600;
    width: auto;
    height: auto;
  }
  .signature-employee-input {
    --padding-top: 12px;
    --padding-bottom: 12px;
    --padding-start: 12px;
    font-size: 16px;
    --placeholder-opacity: 0.8;
    --placeholder-color: gray;
    letter-spacing: 1px;
  }
  .signature-employee-name-label {
    flex: 0 0 60px;
    min-width: 60px;
    max-width: 60px;
    width: 60px;
  }
}

.signature-pad-name {
  margin: auto;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 24px;
  margin-right: 30px;
  margin-bottom: 15px;
}

.signature-pad-name > span {
  text-align: center;
  font-weight: 500 !important;
  font-size: 20px;
  color: #000000 !important;
}

.divider-line {
  border: 0.5px solid #000000;
  margin-bottom: 10px;
}

.edit-time-label {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #828282;
  flex: 1;
  width: 100%;
  height: 20px;
  margin-top: -4px;
}
.edit-time-input-disable .native-input {
  --padding-bottom: 0px;
  --padding-top: 0;
  --padding-start: 0;
}

.edit-helper-text-container {
  display: flex;
  justify-content: space-between;
}
.edit-helper-text {
  line-height: 1em;
}
.save-button,
.edit-button {
  --border-radius: 25px;
  height: 30px;
  width: 100%;
}
.spinner-style {
  height: 70%;
  padding-right: 0%;
}
.timesheet-image {
  flex: 1;
}

.empty-timesheet-image {
  border: 1px solid gray;
  flex: 1;
}

.timesheet-upload-button,
.timesheet-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  flex-grow: 1;
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 590;
  font-size: 14.1202px;
  line-height: 17px;
  border-radius: 80px;
  margin-top: 10px;
}

.timesheet-upload-button {
  margin-right: 15px;
}

.shift-event-log-content {
  background: rgb(228, 228, 228);
  padding: 20px 20px 20px max(env(safe-area-inset-left), 20px);
  min-width: 200px;
}

.timesheet-summary-text {
  width: 100%;
  padding-bottom: 10px;
  align-items: center;
  font-size: 16px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #101925;
}

.timesheet-submit-buttons {
  display: flex;
  justify-content: space-between;
}
.picker-wrapper {
  top: 0;
  height: 58%;
}
.shift-time-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-time-component.view-in-row {
  margin-bottom: 18px;
}

.shift-time-component.view-in-column {
  height: 50px;
}

.signature-confirmation-prompt,
.signature-confirmation-prompt > span {
  text-align: center;
  font-weight: 500 !important;
  font-size: 20px;
  color: #000000 !important;
}

#hcpSignatureSection,
#hcfEmpNameSection,
#hcfEmpInfoSection,
#hcfEmpSignatureSection,
#facilityEmpNameSection,
#facilityEmpSignSection {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

#hcfEmpNameSection,
#hcfEmpInfoSection {
  .signature-employee-padding-keyboard {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    width: 100%;
  }
  .signature-employee-division {
    width: 100%;
    .signature-employee-info-label {
      flex: 0 0 128px;
      font-size: 16px;
    }
    .signature-employee-info-input {
      --padding-top: 0px;
      --padding-bottom: 0px;
      --padding-start: 12px;
      font-size: 16px;
      --placeholder-opacity: 0.8;
      --placeholder-color: gray;
      letter-spacing: 1px;
    }
    .signature-employee-info-input {
      height: 44px;
    }
  }
}

.nfc-instruction-content {
  padding: 100px 40px 20px 40px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.nfc-instruction-info {
  text-align: center;
  font-family: "SF Pro";
  font-style: normal;
}

.nfc-instruction-info.title {
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  margin-top: 10px;
}

@media (max-width: 600px) and (orientation: portrait) {
  .nfc-instruction-info.title {
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    margin-top: 10px;
  }
  .nfc-instruction-info.description {
    text-align: center;
    font-size: 20px;
  }
  .nfc-instruction-info.tag-not-found-link {
    text-align: center;
    height: 4%;
    overflow: hidden;
  }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .nfc-instruction-info.title {
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    margin-top: 10px;
  }
  .nfc-instruction-info.description {
    text-align: center;
    font-size: 30px;
  }
  .nfc-instruction-info.tag-not-found-link {
    text-align: center;
    font-size: 20px;
    height: 4%;
    overflow: hidden;
  }
}

.nfc-instruction-info.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nfc-tap-gif {
  padding-top: 10px;
}
.nfc-scan-cancel-button {
  --border-radius: 25px;
  --background: #fff;
  --box-shadow: none;
  --color: #1c69d1;
  text-decoration: none;
  width: 80%;
  justify-content: center;
  height: 40px;
  font-size: 20px;
  background-color: transparent;
  display: flex;
}

.tag-locations-modal-cancel-button {
  --box-shadow: none;
  --background: #fff;
  color: #000000;
  margin-left: 80%;
  font-size: 30px;
  --padding-top: 15px;
}

.nfc-tag-location-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  height: 50vh;
  padding: 20px;
}

.nfc-tag-location-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
}

.nfc-tag-location-info.title {
  font-weight: 700;
  font-size: 25px;
}

.nfc-tag-location-info.description {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
}

.nfc-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature-employee-name {
  border: 1px solid gray;
  border-radius: 25px;
}

.sign-button.sign-confirmation-continue-button {
  width: 190px;
  height: 30px;
  padding: 0px auto;
  flex: inherit;
}

.signature-pad-name-label {
  margin: 0;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  margin: 0px;
  z-index: 100;
  font-size: 20px;
  margin: 0;
  padding: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.view-in-column {
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin-bottom: 6px;
}

.time-selector-button.view-in-column {
  height: 32px;
  background: #ffffff;
  border: 1px solid #6b7280;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 400;
}

@media (min-width: 700px) {
  .timesheet-summary-text {
    font-size: 20px;
  }

  .close-button {
    font-size: 24px;
  }

  .signature-confirmation-prompt > span {
    font-size: 24px;
  }

  .signature-pad-name,
  .signature-pad-name > span {
    font-size: 24px;
  }

  .shift-event-log-content {
    padding: 20px 20px 20px max(env(safe-area-inset-left), 20px);
    min-width: 240px;
  }
}

.nfc-failure-page {
  font-family: "SF Pro Display";
}

.nfc-failure-container {
  height: 100%;
  padding: 100px 40px 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.nfc-failure-title {
  font-size: 28px;
  font-weight: 800;
}

.nfc-failure-image {
  height: 140px;
  margin-bottom: 60px;
}

.nfc-failure-tips {
  width: 100%;
}

.nfc-failure-tip {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  margin-bottom: 20px;
}

.nfc-failure-tip ion-icon {
  font-size: 36px;
  flex-shrink: 0;
}

.nfc-failure-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  :not(:last-child) {
    margin-bottom: 15px;
  }
}
