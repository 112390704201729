.how-it-works-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 20px rgba(156, 156, 156, 0.1);
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .bullet-item {
    background-color: #5da0fb;
    height: 30px;
    width: 30px;
    color: #fafafa;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: normal;
  }
}
