.not-found-content {
  --background: transparent;
  background: linear-gradient(
    168.65deg,
    #275ab9 0%,
    #33afd5 48.99%,
    #e3e6c4 99.02%
  );
  background-size: cover;

  ion-row.full-height-row {
    height: 100%;
  }

  .logo {
    width: 125px;
    margin: 20px auto;
  }

  h4 {
    color: var(--ion-color-light);
    font-size: x-large;
    font-weight: 300;
  }

  .text-container {
    margin: 10px 0;
    padding: 10px;
    color: var(--ion-color-light);

    &.text-with-points {
      text-align: left;
    }
  }

  .list-items-custom {
    color: var(--ion-color-dark);
    h4 {
      color: var(--ion-color-dark);
      font-size: initial;
      font-weight: initial;
    }
  }
}
