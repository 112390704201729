.header {
  text-align: center;
  padding-top: 1em;
  font-size: medium;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  padding-top: 0;
  font-size: small;
  height: 100%;
  width: 100%;
  text-align: center;
}

.list-container {
  max-height: 40vh;
  overflow: scroll;
  width: 100%;
  margin-bottom: -10px;
}

.text-content {
  justify-content: center !important;
}

.footer {
  background-color: #1c69d1;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 5px 40px;
  color: white !important;
}

.space {
  margin: 1em;
}

.not-now {
  text-decoration: underline;
  font-size: small;
}
