.cancel-modal-close-icon {
  font-size: 24px;
  color: var(--ion-color-medium-shade);
}
.cancelModal {
  --max-height: 85%;
  .modal-wrapper {
    align-self: flex-end;
    .ion-page {
      padding: 15px 15px 30px;
    }
  }
}

.shift-cancel-button {
  font-size: 16px !important;
  --transition: none !important;

  &.button-disabled {
    --ion-color-base: #5a5a5a !important;
  }
}

.reason-description {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 4px;
  padding: 6px;
  border-radius: 5px;
  border: 2px solid #000000ad;
  background: hsl(214deg 13% 96%);
  font-size: 16px;
  margin-top: 0px;

  &.active {
    box-shadow: 0px 2px 6px #00000059;
  }
}

.tell-us {
  margin-bottom: 6px;
  margin-top: 18px;
  font-size: 18px;
}

.cancel-modal-submit-button {
  font-size: 16px;
}

.modal-items {
  --padding-start: 0;
  height: 35px;
  display: flex;
  align-items: center;
}

.modal-radio {
  --size: 14px;
  margin-right: 9px;
  color: var();
}
.modal-reason-title {
  color: var(--ion-color-dark);
}
.checkMarkAnimate {
  // position: absolute;
  padding-bottom: 14px;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.checkmark-circle {
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.checkmark-circle .background {
  width: 50px;
  height: 50px;
  border: 2px solid var(--ion-color-success);
  position: absolute;
  border-radius: 50px;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}
.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 200ms;
  -moz-animation-delay: 200ms;
  animation-delay: 200ms;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.link-text {
  text-decoration: underline;
  font-size: 15px;
  font-weight: 400;
}
.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 22px;
  width: 10px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 2px solid var(--ion-color-success);
  border-top: 2px solid var(--ion-color-success);
  content: "";
  left: 13px;
  top: 27px;
  position: absolute;
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 10px;
    opacity: 1;
  }
  40% {
    height: 22px;
    width: 10px;
    opacity: 1;
  }
  100% {
    height: 22px;
    width: 10px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 10px;
    opacity: 1;
  }
  40% {
    height: 22px;
    width: 10px;
    opacity: 1;
  }
  100% {
    height: 22px;
    width: 10px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 10px;
    opacity: 1;
  }
  40% {
    height: 22px;
    width: 10px;
    opacity: 1;
  }
  100% {
    height: 22px;
    width: 10px;
    opacity: 1;
  }
}
