.rate-negotiation-banner {
  background: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  &.active {
    background: #f3fff8;
    border: 1px solid #219653;
  }

  .rate-negotiation-card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    .rate-negotiation-banner-subtitle {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #828282;
    }
    .rate-negotiation-banner-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #333333;
    }

    .rate-negotiation-banner-chevron {
      color: #bdbdbd;
      &.active {
        color: #219653;
      }
    }
  }
}

.negotiation-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  width: 100%;

  .ion-color-success {
    --ion-color-base: #219653 !important;
  }

  .negotiation-new-rate {
    text-decoration: underline;
    color: #219653;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
  }
  .negotiation-new-rate-hidden {
    display: none;
  }
  .negotiation-not-available {
    font-size: 12px;
    font-weight: 700;
  }
  .has-previous-unassign {
    color: #828282;
    text-decoration: underline;
  }
}
.negotiation-tool-header-title {
  font-size: 17px;
  font-family: Roboto;
}

.negotiation-shift-details-container {
  padding: 0 20px;

  p {
    margin-bottom: 2px;
  }

  .negotiation-shift-details-date-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #4f4f4f;
  }

  .negotiation-shift-details-time-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #333333;
  }

  .negotiation-shift-details-timezone-diff-text {
    background: #d88c33;
    border-radius: 4px;
    width: fit-content;
    padding: 1px 4px;
    color: #ffffff;
    font-weight: 600;
  }

  .negotiation-shift-details {
    &__description {
      font-family: "SF Pro Display";
      color: #4f4f4f;
      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 16.71px;
        margin-bottom: 8px;
        margin-top: 0px;
      }
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 9.55px;
        margin-bottom: 0;
      }
      .address-distance-text {
        color: #828282;
        display: flex;
        align-items: center;
        margin-right: 5px;
        .icon {
          padding: 5px 3px 5px 0px;
          font-size: 1.2em;
        }
      }
    }
  }
}

.negotiation-tool {
  height: 354px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  @media (min-width: 767px) {
    height: auto;
  }

  h1 {
    padding: 0;
    margin: 0;
  }
  p {
    margin-bottom: 0;
  }
  .negotiation-tool-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    #rate-stepper-decrease {
      --ion-color-base: #1c69d1;
      height: 100%;
    }
    #rate-stepper-increase {
      --ion-color-base: #1c69d1;
      height: 100%;
    }
  }
  .negotiation-tool-text {
    font-family: SF Pro Display;
    font-size: 36px;
    font-weight: 500;
    line-height: 43px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    color: #1c69d1;
    &.by-worker {
      color: #bdbdbd;
    }
  }
  .negotiation-tool-amount {
    display: flex;
    align-items: center;
    color: #bdbdbd;
    font-family: SF Pro Display;
  }
  #dollar-text {
    font-size: 43px;
    font-weight: 500;
    @media (max-width: 390px) {
      font-size: 39px;
    }
  }

  #pay-rate-text {
    font-size: 60px;
    font-weight: 500;
    @media (max-width: 390px) {
      font-size: 45px;
    }
  }

  #hr-text {
    font-size: 32px;
    font-weight: 300;
    align-self: end;
    margin-bottom: 10px;
    @media (max-width: 390px) {
      font-size: 28px;
    }
  }

  .stepper-icon {
    height: 47px;
    width: 47px;
  }
}
.network-loading {
  text-align: center;
  padding: 4em 4em;
}

.negotiation-history {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  &.no-border-bottom {
    border-bottom: none;
  }
  p {
    font-size: 13px;
    @media (max-width: 367px) {
      font-size: 12px;
    }
  }
  .history-item {
    padding: 0 20px;
  }
  .history-text {
    font-weight: 500;
  }
  .history-text-bold {
    font-weight: 700;
  }

  .column {
    height: auto;
    &.left {
      text-align: end;
    }
  }
  .hidden-history {
    font-family: SF Pro Display;
    font-weight: 500;
    color: #828282;
  }
  .learn-more-item {
    height: 36px;
    width: 100%;

    .learn-more-text {
      font-weight: 400;
      margin-left: 10px;
    }
  }

  span {
    display: flex;
    align-items: center;
  }

  .ion-text-left {
    margin-right: auto;
  }
}
