$transitionDuration: 200ms;
$transitionTimingFunction: cubic-bezier(0.42, 0, 0.58, 1);

.navigationEnter {
  opacity: 0;
}

.navigationEnterActive {
  opacity: 1;
  transition: all $transitionDuration $transitionTimingFunction;
}

.navigationExit {
  opacity: 1;
}

.navigationExitActive {
  opacity: 0;
  transition: all $transitionDuration $transitionTimingFunction;
}
