.grid-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .custom-style-title {
    font-size: 19px;
    font-weight: bold;
    display: flex;
  }
  b {
    font-size: 16px;
  }
}
