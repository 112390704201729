/**
 * FIXME - find a way to use notistack with css-in-js or styled components.
 * Tested `styled` function, and `sx` props
 */
.SnackbarContainerRoot {
  /**
  * Padding to accommodate device camera notch
  * See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
  */
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  /**
   * Setting z-index to 21000 because since we moved the snackbar
   * to be lower in rendering hierarchy, it is now competing with ion-modal for z-index
   * and ion-modal sets z-index to 20000 (it increses it by 1 for each created modal)
   */
  z-index: 21000;
}
