.ratings-stats-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ratings-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
  }
}

.ratings-card-title {
  margin-top: 15px;
  font-weight: bold;
  font-size: 20px;
  line-height: normal;
  margin-bottom: 0px;
}

.ratings-learn-more-label {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: normal !important;
}

.ratings-re-enrollment-date {
  font-weight: bold;
  font-size: 12px;
  line-height: normal;
}

.ratings-view-cancellation-policy {
  font-weight: bold;
  font-size: 12px;
  line-height: normal;
  margin-left: 16px;
  display: inline-block;
  color: #1890ff;
  cursor: pointer;
  border-bottom: #1890ff 1px solid;
}

.ratings-account-status {
  font-weight: normal;
  line-height: normal;
  border: 1px solid;
  border-radius: 3px;
  overflow: hidden;
  padding: 2px 6px;
}

.ratings-my-attendance-score-container {
  margin: 5px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ratings-score {
  font-weight: 600;
  font-size: 65px;
  line-height: 1;
}

.ratings-green {
  color: #009e0f;
}

.ratings-yellow {
  color: #f1c232;
}

.ratings-red {
  color: #cc0000;
}

.ratings-black {
  color: #000000;
}

.ratings-my-attendance-score-title {
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
}

.ratings-my-attendance-score-description {
  margin: 8px 0 0 0;
  font-weight: normal;
  font-size: 12px;
  line-height: normal;
}

.attendance-score-policy-detail {
  padding-top: 0px;
  padding-bottom: 10px;
  text-align: center;
  ion-item {
    --min-height: 24px;
    --padding-end: 0px;
    --inner-padding-bottom: 0;
    --inner-padding-end: 0px;
    --inner-padding-start: 0;
    --inner-padding-top: 0;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    margin-bottom: 0px;
    display: flex;
    gap: 4px;
    justify-content: space-between;
  }
  ion-note {
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 0px;
    font-size: 13px;
    padding-right: 40px;
    margin-left: 4px;
  }
  ion-label {
    margin: 0;
  }
  .group-attendance-score-label {
    margin: 0;
    white-space: normal;
    flex: none;
  }
  .timeliness-score-label {
    margin: 0;
    white-space: normal;
    flex: none;
  }
}

.ratings-card-sub-title {
  font-size: 14px;
}

.attendance-score-policy-detail-header {
  ion-item {
    padding-inline-start: 0px;
  }
}
