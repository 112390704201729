.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-area {
  display: flex;
  flex-direction: column;
}
.modal-title {
  display: flex;
  justify-content: center;
}
.modal-subtitle {
  display: flex;
  justify-content: center;
  text-align: center;
}
.address-input-container {
  padding: 20px;
  margin-bottom: 20px;
}
.address-input {
  border: 1px solid #c8c8c8;
}
.distance-slider-container {
  margin-top: 30px;
}
