@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/Roboto-Regular.woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/Roboto-Medium.woff");
}

@font-face {
  font-family: "PPNeueMontreal";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../assets/fonts/PPNeueMontreal-Book.woff");
}

@font-face {
  font-family: "PPNeueMontreal";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/PPNeueMontreal-Regular.woff");
}

@font-face {
  font-family: "PPNeueMontreal";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/fonts/PPNeueMontreal-Medium.woff");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/SFProText-Regular.woff");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../assets/fonts/SFProText-Semibold.woff");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/SFProText-Bold.woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/SF-Pro-Display-Regular.woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/fonts/SF-Pro-Display-Regular.woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../assets/fonts/SF-Pro-Display-Semibold.woff");
}

@font-face {
  font-family: "Courier";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/Courier-Regular.ttf");
}
