$calendarActiveDateGrey: #4f4f4f;
$calendarInactiveDateGrey: #dbdbdd;

.urgent-shifts-page {
  .ion-item-divider {
    min-height: 1px !important;
  }

  .urgent-shifts__header-title {
    font-size: 17px;
    line-height: 19.92px;
    text-align: center;
    font-family: Roboto;
  }

  .urgent-shifts-filter-toolbar {
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr;
    margin-top: 2px;

    .urgent-shifts-filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      overflow: unset;

      ion-label {
        font-size: 13pt;
        margin: 0px;
      }

      .urgent-shifts__action-button {
        height: auto;
        font-size: 11pt;
        color: black;
        margin: 0;
        --padding: 0;
        --padding-start: 0;
        --padding-end: 0;

        margin-right: 10px;
      }

      .notification-icon {
        position: relative;
        padding: 0;
        padding-top: 7px;
        font-size: 25px;
        background: transparent;
      }
    }
  }
}

.urgent-shifts-info-modal {
  --height: 300px;
  align-items: flex-end;

  .modal-wrapper {
    border: 1px solid #000;
    border-radius: unset;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .content {
    padding: 20px;
    --overflow: hidden;
  }

  .close-button {
    height: auto;
    font-size: 12pt;
    color: black;
    top: 6px;
    margin: unset;
    --padding: 0;
    --padding-start: 0;
    --padding-end: 0;
  }

  .modal-body {
    padding: 10px 20px 0 20px;

    ul {
      margin: unset;
      padding-left: 18px;
      list-style-type: disc;
    }

    li {
      margin-bottom: 10px;
      text-align: left;
    }
  }

  .info-header {
    margin-top: 18px;
  }
}

.picker-wrapper {
  top: unset !important;
}

.fcf-message {
  text-align: center;
  font-weight: 900;
  padding-left: 5px;
  padding-right: 5px;
}

.no-urgent-shift-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3185c6;
  margin: 5vh 5vw;

  .title {
    display: flex;
    font-weight: 600;
    flex-direction: row;
    font-size: 1.2em;
    align-items: center;

    ion-icon {
      margin-left: 0.2em;
      vertical-align: middle;
    }
  }

  .subtitle {
    font-size: 0.8em;
    margin-top: 5px;
  }

  .information-icon {
    padding: 0px;
  }
}

.always-allow-primer-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.always-allow-primer-modal .modal-wrapper {
  width: 85%;
  max-width: 35em;
  border-radius: 15px;
  max-height: 45em;
  position: absolute;
  display: block;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
    height: 45em;
    color: $calendarActiveDateGrey;

    .bunny-icon {
      max-width: 100px;
      margin-top: 1em;
    }

    .modal-title {
      font-size: 18px;
      font-weight: 700;
      margin-top: 1em;
    }

    .message {
      text-align: center;
      font-size: 10pt;
      margin-top: 15px;
    }
  }
}

.primer-modal .action-container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
  padding: 0.625rem 0rem 1.25rem 0rem;
  align-self: flex-end;

  ion-button {
    width: 100%;
    font-weight: 600;
  }

  .deny-button {
    margin-top: 0.3rem;
    margin-bottom: 0;
    color: $calendarInactiveDateGrey;
    font-weight: 500;
    font-size: 0.9rem;
  }
}
