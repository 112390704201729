.chat-container {
  height: 100%;
  max-width: 1000px;
  margin: auto;

  --sendbird-light-ondark-01: white;
  --sendbird-light-primary-500: #0a67fd;
  --sendbird-light-primary-400: #297bff;
  --sendbird-light-primary-300: #3e87fd;
  --sendbird-light-primary-200: #96bdfd;
  --sendbird-light-primary-100: #b1cfff;

  .sendbird-conversation__footer {
    padding: 0;
    display: flex;
    justify-items: center;
    flex-direction: column;
  }

  .sendbird-conversation__footer__typing-indicator {
    position: relative;
    justify-content: center;
  }

  .sendbird-disabled-chat {
    display: block;
    text-align: center;
    color: gray;
    padding: 12px 0 16px 0;
  }
}

.sendbird-chat-header {
  padding-right: 0;
  padding-left: 8px;
}

.sendbird-chat-header__left {
  margin-right: 0;
}

.sendbird-chat-header__right {
  display: none;
}

.sendbird-chat-header--avatar--group-channel {
  display: none;
}

.sendbird-custom-chat-header {
  padding: 16px 22px;
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  font-size: 22px;
  font-weight: normal;

  .header-description {
    font-size: 16px;
    color: gray;
  }
}

.sendbird-text-message-item-body .sendbird-text-message-item-body__message {
  word-break: break-word;
}

.sendbird-fileviewer
  .sendbird-fileviewer__header
  .sendbird-fileviewer__header__left {
  overflow: hidden;
}

@media (max-width: 600px) {
  .sendbird-dropdown__menu {
    left: 33% !important;
  }
}
