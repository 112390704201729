.professional-reference-ion-content {
  padding: 16px 20px;

  ion-text {
    color: #0F0F0E;
  }
}

.reference-form-content,
.reference-card {
  margin-top: 24px;

  ion-card-title {
    font-size: 22px;
    font-weight: 600;
    color: #0F0F0E;
  }

  ion-card {
    margin: 0px;
    margin-top: 12px;

    ion-input,
    ion-datetime {
      font-size: 18px;
        color: #000000;
        font-weight: 500;
      border-bottom: 1px solid #e0e0e0;
      border-radius: 0;
      --padding-start: 0;
      --padding-bottom: 4px;
      --placeholder-color: #bdbdbd;
      --padding-top: 0px;
      height: auto;
    }
}
  
  .form-control {
    input[type="text"] {
        font-size: 18px;
          font-weight: 500;
          padding-top: 0px;
      }
    
      ion-label {
        p {
          font-size: 16px;
          margin-bottom: 0px;
        }
      }
    ion-note {
      color: #e31937;
    }

    margin-bottom: 8px;
  }
}
.reference-card {

  ion-input>input:disabled,
  .datetime-disabled {
    color: #000000FF !important;
    }
    
    .datetime-disabled {
      border-bottom: 1px solid #a6a6a6 !important;
  }

  .reference-form-content {
    margin-top: 0px;
  }
}

.reference-info-card {
  background-color: #E8F7FF;
  border-radius: 8px;
  margin: 8px 20px;
  padding: 8px;
  font-size: 18px;
    color: #000000;
}

.error-banner {
  padding-top: 8px;
  margin-bottom: 24px;
    font-size: 18px;
  .content {
    padding: 8px;
    border-radius: 8px;
    background-color: #FFECE8;
  }

}
.reference-info-button {
  --background: transparent !important;
  border: 1px solid #1c69d1;
  --color: #1c69d1;
  border-radius: 50px;
  margin: 16px 20px;
}

.reference-card-content {
  display: flex;
  position: relative;
  justify-content: space-between;
  max-width: 100%;
  box-sizing: border-box;
  
  p {
    font-size: 16px;
  }

  .details {
    display: flex;
    flex-direction: row;
    position: relative;
    width: calc(100% - 40px);

    .label {
      min-width: 100px;
        color: #828282;
    }

    .content {
      width: auto;
      margin-left: 4px;
      min-width: calc(100% - 100px);
      color: #0F0F0E;
    }

    .content>p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .image {
    display: block !important;
    width: 32px;
    font-size: 32px;
    margin-left: 4px;
  }
}
.success-banner {
  margin: 8px 0;
  padding: 8px;
  border-radius: 8px;
  background-color: #E4FFE3;
  font-size: 18px;
  }

    .date-time-label {
    left: 0;
    height: 44px;
    display: flex;
    padding-left: 15.4px;
    align-items: center;
    font-size: 16px;
    text-transform: uppercase;
    color: #4F4F4F;
  }
  
  .date-column {
    padding: 0;
}
.header-banner {
  font-size: 18px;
  color: #828282;
}
.reference-button {
  font-size: 22px !important;
}
.date-input-divider {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 27px !important;
  position: relative;

  div {
    margin-top: 9px;
    height: 1px;
    width: 50%;
    max-width: 10px;
    background-color: #c4c4c4;
    /* optional, to visualize the child div */
    text-align: center;
    box-sizing: border-box;
  }
}