.referral-submission {
  .referral-code-input {
    font-weight: 600;
    font-size: 1.2em;
    border-bottom: 2px solid #d3d3d3;
    border-radius: 0;
  }
  .form-heading h4 {
    font-size: 24px;
    font-weight: 800;
  }
  .referral-input-container {
    padding-top: 4em;
  }
  .form-container {
    padding: 1em;
  }
  .form-content b {
    font-size: 1.1em;
  }
  .footer-container {
    .secondary-action {
      font-weight: 600;
    }
  }
}
