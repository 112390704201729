:root {
  ion-header {
    background: var(--ion-color-dark);
  }
  .chip-header {
    background: var(--ion-color-dark);
    .chip-scroll-custom {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      --background: var(--ion-color-dark);
      --color: var(--ion-color-primary-contrast);
      --border-width: 0 !important;
      ion-chip {
        opacity: 1;
      }
      .chip-selected {
        opacity: 1;
        background: var(--ion-color-chip-contrast);
        color: var(--ion-color-dark);
      }
      .chip-disabled {
        opacity: 0.5;
      }
    }
    .chip-scroll-custom::-webkit-scrollbar {
      display: none;
    }
  }
  .supportlink {
    a {
      color: var(--ion-color-primary);
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .centered {
    border: 1px solid var(--ion-color-emptyState-shade);
    background: var(--ion-color-emptyState);
    color: var(--ion-color-emptyState-contrast);
    img {
      width: 150px;
    }
    ion-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .btn-height {
    height: 50px;
    font-size: 20px;
    letter-spacing: 0;
  }
  .shift-detail-title {
    font-size: 20px;
    font-weight: 500;
  }
  .danger {
    --color: var(--ion-color-danger);
  }
  .text-underline {
    text-decoration: underline;
  }
  .no-shadow {
    box-shadow: none;
  }
  .warning-card {
    border: 1px solid var(--ion-color-alert-shade);
    p {
      color: var(--ion-color-ealert-contrast);
    }
  }
  ion-content {
    .margin-top-0 {
      --margin-top: 0;
    }
    .centered-empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      .secondary-heading {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .cards-container {
      ion-list-header {
        ion-label {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
      .ion-card-header-custom-style {
        display: flex;
        justify-content: space-between;
        padding: 10px 16px;
      }
    }
    ion-list-header {
      ion-label {
        font-size: 1.2rem;
        font-weight: 800;
      }
    }
    .card-item-height {
      height: auto;
      ion-label {
        margin: 0px 5px;
        white-space: normal;
        font-size: 14px;
      }
    }
    .ion-message {
      height: 50px;
    }
    .danger {
      --color: var(--ion-color-danger);
    }
    img {
      margin-right: 5px;
    }
    .text-underline {
      text-decoration: underline;
    }
    .footer-shadow {
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
    }
    ion-content {
      .margin-top-0 {
        --margin-top: 0;
      }
      .centered-empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        .secondary-heading {
          font-weight: 600;
          font-size: 18px;
        }
      }
      .cards-container {
        ion-list-header {
          ion-label {
            font-size: 1.2rem;
            font-weight: 600;
          }
        }
        .ion-card-header-custom-style {
          display: flex;
          justify-content: space-between;
          padding: 10px 16px;
        }
      }
      ion-list-header {
        ion-label {
          font-size: 20px;
          font-weight: 800;
        }
      }
      .card-item-height {
        height: auto;
        ion-label {
          margin: 0px 5px;
          white-space: normal;
          font-size: 14px;
        }
      }
      .ion-message {
        height: 50px;
      }
      ion-avatar {
        width: 20px;
        height: 20px;
      }
    }
    ion-chip {
      margin-left: 0;
    }
    .header-padding {
      --padding-bottom: 5px;
    }
    .shift-timeline {
      margin-left: 20px;
      ion-item {
        --padding-start: 5px;
        ion-label {
          margin: 0;
        }
      }
    }
    .time-elapsed {
      text-align: center;
      .time {
        font-size: 16px;
        font-weight: 600;
      }
      .second {
        font-size: 10px;
      }
    }
    .chip-header {
      background: var(--ion-color-dark);
      .chip-scroll-custom {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        --background: var(--ion-color-dark);
        --color: var(--ion-color-primary-contrast);
        --border-width: 0 !important;
        ion-chip {
          opacity: 1;
        }
        .chip-selected {
          opacity: 1;
          background: var(--ion-color-chip-contrast);
          color: var(--ion-color-dark);
        }
        .chip-disabled {
          opacity: 0.5;
        }
      }
      .chip-scroll-custom::-webkit-scrollbar {
        display: none;
      }
    }
    .primary-btn,
    .uplaod-btn-primary {
      width: 80%;
      height: 50px;
      font-size: 20px;
    }
    .cancel-btn {
      --background: transparent;
      --color: var(--ion-color-danger);
      --box-shadow: none;
    }
  }
  .shift-item {
    position: relative;
    .undo-overlay {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 10px;
      z-index: 2;
      .undo-button {
        position: absolute;
        right: 10px;
        bottom: 10px;
        text-decoration: underline;
      }
    }
  }
  .btn-green {
    color: #2ecc71;
  }
  .action-sheet-title.sc-ion-action-sheet-ios {
    font-size: 20px;
    font-weight: 500;
    color: black;
  }
  .action-btn-row {
    display: flex;
    justify-content: center;
    .upload-timesheet-button {
      align-self: center;
      justify-content: center;
      display: flex;
    }
    .no-timesheet-select {
      margin-bottom: -10px;
    }
    .reupload-button {
      color: #1c69d1;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      --padding-start: 0px;
    }
  }
  .non-ip-shift-message {
    line-height: 1.4;
  }
}
