.page-segment {
  display: block;
}

.page-segment.hidden {
  display: none;
}

.segment-map-calendar {
  width: 100%;
  background-color: #59647a;
  margin: 0em 0.5em 0em 0.5em;

  .segment-button {
    font-weight: 500 !important;
  }

  .segment-button-checked {
    color: var(--ion-color-dark) !important;
    font-weight: 600 !important;
  }
}

.shift-segment-visible {
  visibility: visible !important;
  opacity: 1;
  display: flex !important;
}

.open-shift-calendar-v2-page-layout {
  // IonPage has justify-content: space-between which disrupts the layout for open shift calendar v2
  // We use this style to correct the layout when open shift calendar v2 is enabled
  justify-content: start;
}

.danger-color {
  color: var(--ion-color-danger) !important;
}

.shift-segment-hidden {
  visibility: none !important;
  opacity: 0;
  display: none !important;
}

.warning-banner,
.danger-banner {
  margin: auto;
  width: max-content;
  padding: 4px 8px;
  border-radius: 2px;
  color: hsl(0deg 0% 0% / 77%);

  @media (max-width: 900px) {
    width: unset;
    margin-left: 12px;
    margin-right: 12px;
  }

  margin-top: 12px;

  .anticon {
    margin-right: 4px;
  }
}

.warning-banner {
  background: #ffd997;
  border: 1px solid #ff6b00;

  a {
    color: #ff6b00;
  }

  a:link {
    color: #ff6b00;
  }

  a:visited {
    color: #ff6b00;
  }

  a:active {
    color: #ff6b00;
  }

  .anticon {
    color: #ff6b00;
  }
}

.danger-banner {
  background: #f4c0ba;
  border: 1px solid #ca4031;

  .anticon {
    color: #ca4031;
  }
}

ion-segment-button::before {
  border-left: none !important;
}

.ion-segment-button-separator {
  border-left: 1px solid #eeeeeecf !important;
  margin: 8px 1px;
}

.account-restriction-banner {
  background-color: #1890ff;
  color: white;
  border-radius: 8px;
  padding: 5px;
  margin: 8px 8px 25px;
  font-family: SF Pro Display;
  font-weight: 700;
  font-size: 12px;
  line-height: 14.32px;
  text-align: center;
  color: white;
}

.open-shift-map-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
