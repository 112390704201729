.negotiation-center-header-title {
  font-size: 17px;
  font-family: Roboto;
}

#content-cards-container {
  .ab-feed-buttons-wrapper {
    display: none;
  }

  .ab-feed {
    &.ab-show {
      width: auto;
    }
    .ab-feed-body {
      padding: 0px;
    }

    .ab-no-cards-message {
      visibility: hidden;
      line-height: 0;
      margin-top: 20px;
    }
    .ab-no-cards-message::after {
      content: "No notifications received in the last 30 days";
      visibility: visible;
      display: block;
    }
    .ab-card {
      margin-bottom: 0;
    }
    .ab-pinned-indicator {
      display: none;
    }
  }
}
