.need-access-toast {
  color: black;
}

.shift-in-progress-toast {
  top: 37px;
  --color: white;
  --background: #da8300; // FIXME: This bg color of the toast does not change even with this.
  --width: 280px;
  --height: 35px;
  --border-radius: 3px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // --border-color: #da8300;
  --border-width: 1px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: calc(100px + var(--height));
}

// FIXME: None of these styles get applied even though the class is present on the correct html element.
.shift-in-progress-button {
  background: rgba(255, 255, 255, 0.3);
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-width: 1px;
}
