.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.shift-list__header-title {
  font-size: 17px;
  line-height: 19.92px;
  text-align: center;
  font-family: Roboto;
}

.shift-list__button-back {
  position: relative;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: 0;
  left: -12px;
}

.shift-list {
  .shift {
    border-bottom: 1px solid var(--ion-color-medium-tint);
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
    padding: 15px 10px;

    &:last-child {
      border: none;
    }

    .shift-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h6 {
      font-size: 16px;
      margin-bottom: 1px;
    }

    p {
      font-size: 12px;
    }
  }
}

.no-shifts-image {
  width: 10rem;
}

.ion-instant-text {
  color: var(--ion-color-grey-text) !important;
  margin: 5px 0 !important;
}

.instant-book-btn {
  --border-radius: 7px;
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 20px;
  --padding-bottom: 20px;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: var(--ion-color-text-blue);
  border-color: var(--ion-color-text-blue);
}

.instant-book-action-content {
  text-align: right;
}

.instant-action-btn {
  position: relative;
  left: 40%;
}

.tooltip-instant-book-btn {
  position: relative;
  display: inline-block;
  top: 167px;
  left: 20vw;

  .tooltip-action-btn {
    padding: 5px;
    color: var(--ion-color-alert-shade);
    display: block;
    position: relative;
    bottom: 5px;
    text-align: right;
  }

  .tooltiptext {
    min-height: 70px;
    width: 70vw;
    background-color: var(--ion-color-dark-black);
    color: var(--ion-color-primary-contrast);
    text-align: left;
    padding: 10px;
    border-radius: 12px;
    position: absolute;
    z-index: 99999;
    font-size: 12px;
    white-space: pre-line;

    &::after {
      content: " ";
      position: absolute;
      top: -12px;
      right: 14px;
      margin-left: -5px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 20px solid var(--ion-color-dark-black);
    }
  }
}

.tooltip-instant-action-btn {
  position: relative;
  display: inline-block;
  top: 107px;
  left: 29vw;

  .tooltip-action-btn {
    position: relative;
    text-align: right;
    top: 0;
    padding: 5px;
    color: var(--ion-color-alert-shade);
    display: block;
  }

  .tooltiptext {
    height: 85px;
    width: 70vw;
    background-color: var(--ion-color-dark-black);
    color: var(--ion-color-primary-contrast);
    text-align: left;
    padding: 10px;
    border-radius: 12px;
    position: absolute;
    z-index: 99999;
    font-size: 12px;

    &::after {
      content: " ";
      position: absolute;
      top: -11px;
      right: 13px;
      margin-left: -5px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 15px solid var(--ion-color-dark-black);
    }
  }
}

// style for tooltip if screen size is less than 370px in width
@media only screen and (max-width: 370px) {
  .tooltip-instant-book-btn {
    left: 5vw;

    .tooltip-action-btn {
      padding: 5px;
      color: var(--ion-color-alert-shade);
      display: block;
      position: relative;
      bottom: 5px;
      text-align: right;
    }

    .tooltiptext {
      min-height: 70px;
      width: 80vw;
    }
  }
}

// backup team accrordion style start
.site-collapse-custom-collapse {
  .site-collapse-custom-panel {
    background: rgba(172, 224, 244, 0.2);
    margin: 10px 15px !important;
    border-radius: 10px !important;
    overflow: hidden;
    border: 1px solid #ace0f4;
  }
}

.instant-book-confirmation-modal {
  .alert-title {
    text-align: left;

    h2 {
      margin: 0;
    }
  }

  .alert-message {
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
    margin-top: -5px;

    span {
      padding-top: 12px;
      padding-bottom: 2px;
      display: inline-block;
      color: var(--ion-color-light-contrast);
    }

    a {
      color: var(--ion-instant-book-modal-text) !important;
    }
  }
}

.popup-info-modal {
  .alert-title {
    display: none;
  }

  .alert-message {
    text-align: left;
    font-size: 12px;

    span {
      padding: 0;
      padding-bottom: 4px;
      display: inline-block;
      color: var(--ion-color-light-contrast);
    }

    a {
      color: var(--ion-instant-book-modal-text) !important;
    }
  }
}

.shift-ion-button.ion-button {
  font-family: "SF Pro Display";
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  font-weight: 600;
  --ion-color-primary: #1c69d1;
  --ion-color-secondary: #828282;
  --ion-color-shift-priority: #6a67ce;
  min-height: 40px;
  min-width: 246px;

  ion-label {
    overflow: visible;
  }
}

.shift-ion-time-range-text.ion-label {
  --ion-font-family: "SF Pro Text";
  font-weight: 600;
  line-height: 23.87px;
  --color: #333;
  white-space: normal;
}

.list-title {
  font-family: Roboto;
  font-weight: 700;
  font-size: 23px;
  line-height: 26.95px;
  letter-spacing: -0.3px;
  color: #333;
}

.shift-item-alert {
  font-family: "SF Pro Text";

  div {
    display: flex;
  }

  .alert-wrapper {
    border-radius: 15px;
  }

  .alert-head {
    display: none;
    /* removing this because it only support text, but we need to support html to render icons, it's functionality is handled by .alert-message */
  }

  .alert-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000000;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.08px;
    font-weight: 400;
    text-align: center;
    overflow-y: hidden;
    margin-top: 24px;
    /* This overrides padding-top: 0 set by ion classes. */
    padding: 0 16px;

    &.sc-ion-alert-md,
    &.sc-ion-alert-ios {
      max-height: fit-content;
    }

    h1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.41px;
      text-align: center;
      color: #000000;

      span {
        color: #eb5757;

        &.missing-doc-count {
          color: #1c69d1;
        }
      }

      img {
        color: #1c69d1;
        height: 20px;
      }
    }

    .information {
      font-weight: 700;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;

      p {
        margin-bottom: 0;
      }
    }
  }

  .alert-button-group {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    border-top: 1px solid #dbdbdd;
    color: #007aff;
    padding: 0;

    button {
      display: flex;
      flex: 1;
      font-weight: 600;
      color: #3d85b8;

      .alert-button-inner {
        justify-content: center;
        /* removed default justify-content: flex-end; */
      }
    }

    button:last-child {
      font-weight: 600;
    }

    > button + button {
      border-left: 1px solid #dbdbdd;
    }
  }
}

.extra-time-confirm-button {
  color: #3d85b8 !important;
  font-weight: bold;
}

.extra-time-ok-button {
  color: #3d85b8 !important;
}

.shift-item {
  &__container {
    position: relative;
    --padding: 8px;
    border: solid 1px #e0e0e0;
    border-radius: 8px;
    margin: 12px 20px;
    box-shadow: 0px 4px 4px 0px #00000040;

    .item-native {
      --padding-start: 8px;
      --padding-end: 8px;
    }
  }

  &__container-shift-priority {
    position: relative;
    --ion-item-background: rgba(106, 103, 206, 0.15);
    --padding: 8px;
    border: solid 1px #6a67ce;
    border-radius: 8px;
    margin: 12px 20px;
    box-shadow: 0px 4px 4px 0px #00000040;

    .item-native {
      --padding-start: 8px;
      --padding-end: 8px;
    }
  }

  &__time-range {
    font-family: "SF Pro Display";
    font-weight: 500;
    font-size: 20px;
    line-height: 23.87px;
    color: #333;
  }

  &__title-link {
    text-decoration: underline;
  }

  &__inline-alerts {
    font-family: "SF Pro Display";
    font-weight: 600;
    font-size: 11px;
    display: flex;
    padding: 2px 0;
    justify-content: center;
    text-align: center;

    .inline-alert {
      white-space: normal;

      .inline-alert-icon {
        min-width: 0.813rem;
        min-height: 0.813rem;
        display: inline-block;
        margin-right: 2px;
        vertical-align: text-top;

        &.missing-doc {
          color: #1c69d1;
        }

        &.covid-pay {
          color: #eb5757;
        }

        &.covid-test {
          width: 0.813rem;
          height: 0.813rem;
        }
      }

      span {
        color: #eb5757;

        &.missing-doc-count {
          color: #1c69d1;
        }
      }
    }
  }

  &__work-confirmed {
    font-family: "SF Pro Display";
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #4f4f4f;
  }

  &__info-icon {
    font-size: 18px;
    color: #bdbdbd;
    margin: auto;
  }

  &__description {
    font-family: "SF Pro Display";
    color: #4f4f4f;

    h3 {
      font-weight: 400;
      font-size: 14px;
      line-height: 16.71px;
    }

    p {
      font-weight: 500;
      font-size: 8px;
      line-height: 9.55px;
      margin-bottom: 0;
    }

    .no-margin-top {
      margin-top: 0;
    }

    .address-text {
      color: #828282;
      display: flex;
      align-items: center;
      margin-right: 5px;

      .icon {
        padding: 5px 3px 5px 0px;
        font-size: 1.2em;
      }
    }

    .distance-text {
      color: #828282;
      display: flex;
      align-items: center;
      margin-right: 5px;

      .icon {
        padding: 5px 3px 5px 0px;
        font-size: 1.2em;
      }
    }
  }

  &__text-waiting-confirmation {
    font-family: "SF Pro Display";
    color: #4f4f4f;
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    text-align: center;
    padding: 24px 0;
  }
}

.border {
  display: inline-block;
  width: 98%;
  height: 48px;
  border-radius: 5px;
  position: absolute;
  left: 1px;
  top: 48px;
  padding: 10px;
  animation: pulse 1.2s infinite !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(107, 150, 233, 0.8);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(107, 150, 233, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(107, 150, 233, 0);
  }
}

.remove-extra {
  .item-lines-full {
    width: 0px !important;
  }
}

.etp-dist-video-wrapper {
  font-size: 12px;
}

.small-chip-button {
  margin-bottom: auto;
  padding: 0 !important;

  .tag-type-clip-button {
    margin: 0;
    opacity: 1;
  }

  .tag-type-clip-text {
    color: #3171e0;
    max-width: 180pt;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.previously-unassigned-modal {
  max-width: 320px;

  .previously-unassigned-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ion-button {
      font-weight: 600;
    }
  }
}
