.colleague-profile-picture-list {
  display: flex;
  max-width: 165px;
  overflow: hidden;
  padding: 5px;

  .colleague-profile-pic-avatar {
    position: relative;
    width: 40px;
    height: 40px;
    background: var(--ion-color-alert-shade);
    text-align: center;
    margin-right: 15px;
    border-radius: 20px;
    box-shadow: 0 0 0 3px #50555c;
    flex: 1 0 auto;
    .colleague-initial-avatar {
      font-size: 24px;
      color: white;
      margin: 0px;
      line-height: 40px;
    }
  }
  .colleague-profile-pic-avatar-border {
    box-shadow: 0px 0px 0px 2px #ffffff, 0 0 0 5px #50555c;
  }
}

.colleague-list-row {
  background: #ace0f4;
  border: 2px solid #1c69d1;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  margin: 20px 20px;
  padding: 10px;
  text-align: left;
}

.colleague-info-row {
  margin-bottom: 20px;
  .colleague-info-details {
    align-self: center;
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .add-friend-icon {
      cursor: pointer;
      margin-left: 16px;
      min-width: 32px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .colleague-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin-left: 10px;
      max-width: 200px;
      p {
        font-family: SF Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin: 3px;
        text-align: start;
      }
      .colleague-work-details {
        font-size: 10px;
        text-align: start;
      }
    }
  }

  .colleague-info-avatar {
    align-self: center;
    margin-left: 30px;
    .profile-pic-avatar {
      width: 60px;
      height: 60px;
      box-shadow: 0 0 0 3px #50555c;
      background: var(--ion-color-alert-shade);
      p {
        font-size: 36px;
        line-height: 60px;
        color: white;
        margin: 0px;
      }
    }
    .profile-pic-avatar-border {
      box-shadow: 0px 0px 0px 2px #ffffff, 0 0 0 5px #50555c;
    }
  }
}

.profile-pic-avatar-border {
  box-shadow: 0px 0px 0px 2px #ffffff, 0 0 0 5px #50555c;
}

.work-with-friends {
  margin: 10px;
  justify-content: space-between;
  align-items: end;
  cursor: pointer;
  .show-colleague-list-link {
    display: flex;
    font-style: normal;
    font-size: 9px;
    text-decoration: underline;
    align-items: center;
    white-space: nowrap;
  }
  .colleague-picture-list-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  .colleague-summary {
    #colleague-summary-title {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 5px;
      color: black;
    }
    .colleague-summary-subtitle {
      font-family: SF Pro Display;
      font-size: 9px;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: 0em;
      text-align: left;
      color: #828282;
      margin-bottom: 5px;
    }
  }
}

.profile-picture-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  .profile-picture-modal-container {
    text-align: center;
    max-width: 350px;
    border-radius: 16px;
    font-style: normal;

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    em {
      font-size: 14px;
      line-height: 17px;
    }
    .worker-friend-initial-avatar {
      font-size: 24px;
      color: white;
      margin: 0px;
      line-height: 40px;
    }
    .profile-picture-modal-container-content {
      margin: 20px 30px 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      gap: 20px;
      .profile-picture-modal-avatar {
        border: "solid white";
      }
      .modal-subtitle {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
      }
      .colleague-profile-pic-avatar {
        width: 60px;
        height: 60px;
        background: var(--ion-color-alert-shade);
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #50555c;
        text-align: center;
        margin-right: 10px;

        .colleague-initial-avatar {
          font-size: 24px;
          color: white;
          margin: 0px;
          line-height: 40px;
        }
      }
    }
    .profile-picture-modal-container-action {
      margin: 20px;
      .profile-picture-modal-cancel-action {
        --border-color: #828282;
        --border-width: 2px;
        --color: #828282;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.profile-picture-modal .modal-wrapper {
  width: 80%;
  height: 450px;
  max-width: 350px;
  margin: 30px;
  position: absolute;
  display: block;
  border-radius: 16px;
  min-height: 23rem;
}

.book-with-friend-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  .book-with-friend-modal-container {
    text-align: center;
    max-width: 350px;
    border-radius: 16px;
    font-style: normal;

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    em {
      font-size: 14px;
      line-height: 17px;
    }
    .book-with-friend-modal-container-content {
      margin: 30px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      gap: 20px;
      .modal-subtitle {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
      }
      .description {
        color: #333333;
      }
      .attention-warning {
        font-weight: 700;
      }
    }
    .book-with-friend-modal-container-action {
      margin: 20px;
    }
  }
}

.book-with-friend-modal .modal-wrapper {
  width: 80%;
  height: 440px;
  max-width: 350px;
  margin: 30px;
  position: absolute;
  display: block;
  border-radius: 16px;
  min-height: 22rem;
}

.invite-friend {
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  align-self: center;
  margin-bottom: 13px;
}

.book-with-friends-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
}

.colleague-list-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  z-index: 30000;
  .modal-wrapper {
    margin: 30px;
    position: absolute;
    display: block;
    border-radius: 16px;
    min-height: 22rem;
  }
}

.colleague-list-page {
  opacity: 1;
}

.colleague-list-back-button {
  position: absolute;
}

@media (min-width: 400px) {
  .colleague-profile-picture-list {
    max-width: 285px;
  }
}

.friends-list-no-friends-label-container {
  margin-top: 106px;
  text-align: center;
}

.friends-list-select-container {
  display: flex;
  justify-content: center;
  margin: 48px;
  margin-top: 32px;
}

.friends-list-status-select {
  margin: 0 14px;
  width: calc(100% - 28px);

  ion-segment-button {
    min-width: auto;
  }
}

.friends-list-info-container {
  background-color: #ace0f4;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 12px;
  border-radius: 4px;
  margin: 16px;
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.friends-list-add-friend-button-container {
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.friends-list-add-friend-button-icon {
  margin-right: 6px;
}

.friends-list-no-friends-label {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.friends-list {
  margin-top: 16px;
  margin-bottom: 16px;
}

.worker-friend {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.friends-list-friend-info {
  margin-left: 8px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

.friends-list-friend-name {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2px;
}

.friends-list-friend-subtitle {
  font-family: "SF Pro";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2px;
}

.worker-friend-action-buttons-container {
  margin-left: 8px;
}

.worker-friend-request-decline-button {
  background: #f2f2f2;
  color: #000000;
  border-radius: 8px;
}

.worker-friend-request-accept-button {
  margin-left: 8px;
  border-radius: 8px;
}

.worker-friend-remove-button {
  background: #f2f2f2;
  border-radius: 6px;
  color: #000000;
}

.friends-list-friend-info-container {
  margin-left: 3px;
  display: flex;
  align-items: center;
  max-width: 65%;
}

.worker-friend-profile-pic-avatar {
  width: 40px;
  height: 40px;
  background: var(--ion-color-alert-shade);
  text-align: center;
  margin-right: 8px;
  border-radius: 20px;
  box-shadow: 0 0 0 3px #50555c;
  flex: 1 0 auto;
}

.worker-friend-profile-pic-avatar-border {
  box-shadow: 0px 0px 0px 2px #ffffff, 0 0 0 5px #50555c;
}

.worker-friend-initial-avatar {
  font-size: 24px;
  color: white;
  margin: 0px;
  line-height: 40px;
}

.friend-confirmation-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  .friend-confirmation-modal-container {
    text-align: center;
    max-width: 350px;
    border-radius: 16px;
    font-style: normal;

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    em {
      font-size: 14px;
      line-height: 17px;
    }
    .friend-confirmation-modal-container-content {
      margin: 30px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      gap: 20px;
      .modal-subtitle {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
      }
    }
    .friend-confirmation-modal-container-action {
      margin: 20px;
      .friend-confirmation-modal-cancel-action {
        --border-color: #828282;
        --border-width: 2px;
        --color: #828282;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.friend-confirmation-modal .modal-wrapper {
  width: 80%;
  height: 350px;
  max-width: 350px;
  margin: 30px;
  position: absolute;
  display: block;
  border-radius: 16px;
  min-height: 22rem;
}

.add-friend-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  .add-friend-modal-container {
    text-align: center;
    max-width: 350px;
    border-radius: 16px;
    font-style: normal;

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    em {
      font-size: 14px;
      line-height: 17px;
    }
    .add-friend-modal-container-content {
      margin: 30px;
      margin-top: 5px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      gap: 20px;
      .modal-subtitle {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
      }
    }
    .add-friend-modal-container-action {
      margin: 20px;
      .add-friend-modal-cancel-action {
        --border-color: #828282;
        --border-width: 2px;
        --color: #828282;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.add-friend-modal .modal-wrapper {
  width: 80%;
  height: fit-content;
  max-width: 350px;
  margin: 30px;
  position: absolute;
  display: block;
  border-radius: 16px;
  min-height: 22rem;
}
.friendIconContainer {
  z-index: 2;
  position: absolute;
  width: 15px;
  height: 15px;
  right: -8px;
  bottom: -2px;
  background-color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-dark-tint);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .friendIcon {
    height: 10px;
    width: 10px;
  }
}

.colleagueFriendIconContainer {
  z-index: 2;
  position: absolute;
  width: 15px;
  height: 15px;
  right: 8px;
  bottom: -2px;
  background-color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-dark-tint);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .friendIcon {
    height: 10px;
    width: 10px;
  }
}

.friend-link-alert-modal .modal-wrapper {
  height: auto;
}

.ion-friend-link-alert-popup {
  --max-width: 320px;
  .alert-wrapper {
    padding-top: 10px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    padding: 10px;
    .alert-head {
      .alert-title {
        font-family: SF Pro Display;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
      }
    }
    .alert-message {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      a {
        text-decoration: underline;
      }
    }
    .alert-button-group {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .alert-button {
        border: 2px solid #032e68;
        border-radius: 32px;
        text-transform: none;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #032e68;
        margin: 0;
        .alert-button-inner {
          justify-content: center;
        }
      }
      .ion-login-alert-ok-button {
        border: 2px solid #828282;
        color: #828282;
      }
    }
    .alert-head {
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
    }
  }
}
