.login-content {
  --background: transparent;
  background: linear-gradient(168.65deg,
      #275ab9 0%,
      #33afd5 48.99%,
      #e3e6c4 99.02%);
  background-size: cover;

  .content-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .logo {
    width: 100px;
    margin: 20px auto;
  }

  .verify-sent-header {
    background: transparent;

    &:after {
      content: none;
    }
  }

  .verify-sent-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .verify-sent-top-container {
      padding-top: 0;
      height: 33%;
      transform: scale(0.8);
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: var(--ion-color-light);
      margin: 0;
    }
  }

  h3,
  h4,
  h5 {
    color: var(--ion-color-light);
  }

  h4 {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
  }

  ion-item {
    border-radius: 5px;
    margin-bottom: 10px;
  }

  ion-input {
    background: var(--ion-color-light);
  }
}

.login-button {
  margin-top: 20px;
  --border-radius: 50px;
  --background: #1c69d1;
  font-size: 16px;
}

.login-top-container {
  display: flex;
  padding-top: 10%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 0px;
  }
}

.ion-margin-bottom {
  margin-bottom: 5px;
}

.cbh-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    line-height: 1.2;
  }

  .cbh-link {
    width: auto;
    margin: 0;
    padding: 0;
    --padding-start: 6px;
    --padding-end: 0;
  }
}

.login-link {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin-top: 60px;
  color: #4f4f4f;

  .cbh-link {
    font-weight: inherit;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
  }
}

.login-form {
  ion-icon {
    margin-inline-end: 16px;
  }

  input {
    font-size: 16px !important;
  }

  .login-link {
    margin-top: 10px;
  }
}

.or-email-text-divider {
  margin: "20px 0 0px";
}

@media (max-width: 360px) {
  .login-content {
    p.or-seperator {
      margin: 8px;
    }
  }
}
